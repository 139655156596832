import React, { useMemo, useState, useRef, useEffect } from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import { Link, useIntl } from 'gatsby-plugin-intl-v6'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Certificate from '../static/images/foundation/Certificate-of-Incorporation.jpg'
import { any } from 'prop-types'
import { Carousel } from 'antd';
import mask1 from '../static/images/committees/mask-1.png'
import mask2 from '../static/images/committees/mask-2.png'
import mask3 from '../static/images/committees/mask-3.png'
import banner from '../static/images/foundation/banner-new.png'
import coin from '../static/images/foundation/coin.png'
import { motion } from "framer-motion";
import { useViewportAnimation } from '../utils/interaction'

export default Foundation

function Foundation() {
  let pageModel = new PageModelTitle('AB Foundation', 'foundation-page')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const locale = intl.locale
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWebExpanded, setIsWebExpanded] = useState(false);
  const [isH5Expanded, setIsH5Expanded] = useState(false);

  const allMdx = useStaticQuery(graphql`
    query DoubleMdxQuery {
      directors: allMdx (
        filter: { slug: { glob: "directors/*" } },
        sort: { fields: [frontmatter___enName], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              language
              description
              synopsis
              orderby
              ABRank
              enName
            }
          }
        }
      }
      committees: allMdx (
        filter: { slug: { glob: "committee/*" } },
        sort: { fields: [frontmatter___enName], order: ASC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              language
              description
              orderby
              enName
              ABRank
              iconName
            }
          }
        }
      }
    }
  `)
  
  const filteredData = useMemo(() => {
    const filtered = (allMdx.directors?.edges || []).filter(
      (edge: any) => edge.node.frontmatter?.language === locale
    )
    const filteredCommittees = (allMdx.committees?.edges || []).filter(
      (edge: any) => edge.node.frontmatter?.language === locale
    )
    return [filtered, filteredCommittees]
  }, [allMdx, locale])
  // console.log('filteredData:', filteredData)

  const directors = filteredData[0]
  const committees = filteredData[1]
  const chairman = directors[0].node
  const members = directors.slice(1)
  // web端补充空值方便排列
  // const lineNum = 5
  // const padding = committees.length % lineNum > 0 ? Array(lineNum - committees.length % lineNum).fill(null) : [];
  // const committeesWeb = [...committees, ...padding]
  // 轮播数据
  // const pagesWeb = Array.from({ length: Math.ceil(committeesWeb.length / 10) }, (_, i) =>
  //   committeesWeb.slice(i * 10, (i + 1) * 10)
  // )
  // const pagesH5 = Array.from({ length: Math.ceil(committees.length / 4) }, (_, i) =>
  //   committees.slice(i * 4, (i + 1) * 4)
  // )
  const [ref1, controls1] = useViewportAnimation();
  const [ref2, controls2] = useViewportAnimation();
  const [ref3, controls3] = useViewportAnimation();
  const [ref4, controls4] = useViewportAnimation();
  const [ref5, controls5] = useViewportAnimation();
  const [ref6, controls6] = useViewportAnimation(0.2);
  const [ref7, controls7] = useViewportAnimation(0.2);
  const [ref8, controls8] = useViewportAnimation();

  return (
    <div>
      <div className={'banner-pc container'}>
        <div className={'banner-centent'}>
          <div className={'banner-news'}>
            <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className={'banner-title'}
              >
              <span>AB</span> {intl.formatMessage({ id: 'Charity Foundation' })}
            </motion.div>
            <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }} 
              className={'banner-sub-title'}>
            <span>{intl.formatMessage({ id: 'AB Foundation' })}</span>
              {intl.formatMessage({ id: 'The AB Foundation is dedicated to serving as a bridge between blockchain innovation and social welfare. We drive the development of the public chain ecosystem, establishing a transparent, efficient, and inclusive blockchain infrastructure to serve social good through technology.' })}
            </motion.div>
            <div className='banner-img'>
              <div className='banner-img-box'>
                <img className='banner' src={banner} />
                <motion.img className='coin1'
                  src={coin} 
                  animate={{
                    y: ["0px", "-20px"]
                  }}
                  transition={{
                    duration: 2,
                    yoyo: Infinity, // 无限往返
                    ease: "easeInOut"
                  }}/>
                <motion.img className='coin2'
                  src={coin}
                  animate={{
                    y: ["0px", "20px"]
                  }}
                  transition={{
                    duration: 2,
                    yoyo: Infinity,
                    ease: "easeInOut",
                    delay: 1 // 延迟1秒形成交替
                  }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='banner-mobile-box'>
        <div className={'banner-mobile container'}>
          <div className={'banner-centent'}>
            <div className={'banner-news'}>
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className={'banner-title'}
              >
                <span>AB</span> {intl.formatMessage({ id: 'Charity Foundation' })}
              </motion.div>
              <motion.div 
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className={'banner-sub-title'}
              >
                <span>{intl.formatMessage({ id: 'AB Foundation' })}</span>
                {intl.formatMessage({ id: 'The AB Foundation is dedicated to serving as a bridge between blockchain innovation and social welfare. We drive the development of the public chain ecosystem, establishing a transparent, efficient, and inclusive blockchain infrastructure to serve social good through technology.' })}
              </motion.div>
              <div className='banner-img'>
                <div className='banner-img-box'>
                  <img className='banner' src={banner} />
                  <motion.img className='coin1' 
                    src={coin} 
                    animate={{
                      y: ["0px", "-20px"]
                    }}
                    transition={{
                      duration: 2,
                      yoyo: Infinity, // 无限往返
                      ease: "easeInOut"
                    }}
                   />
                  <motion.img className='coin2' 
                    src={coin}
                    animate={{
                      y: ["0px", "20px"]
                    }}
                    transition={{
                      duration: 2,
                      yoyo: Infinity,
                      ease: "easeInOut",
                      delay: 1 // 延迟1秒形成交替
                    }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='vision-mission container'>
        <p className='title title-pc'>{intl.formatMessage({ id: 'Vision and Mission of the Foundation' })}</p>
        <p className='title title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
          {intl.formatMessage({ id: 'Vision and Mission of the Foundation' })}
          </span>
        </p>
        <div className='content-box'>
          <motion.div className='content-item mb-6 md:mb-0'
            ref={ref1}
            initial={{ x: -120, opacity: 0 }}
            animate={controls1}
            transition={{ duration: 0.8 }}
          >
            <StaticImage
              alt="vision"
              class='icon'
              src="../static/images/foundation/icon-vision.png"
            ></StaticImage>
            <p className='subtitle'>{intl.formatMessage({ id: 'Vision' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'To become a bridge between blockchain innovationand social welfareproviding value and trust support for global users and institutions.' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Promote the empowerment of social welfare through blockchain technology, enhancing transparency and trust.' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Connect global resources, foster multi-party collaboration, and build a decentralized charitable ecosystem.' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Leverage technology to support social responsibility, drive sustainable development, and achieve a fairer public welfare system.' })}</p>
          </motion.div>
          <motion.div className='content-item'
            ref={ref2}
            initial={{ x: 120, opacity: 0 }}
            animate={controls2}
            transition={{ duration: 0.8 }}
          >
            <StaticImage
              alt="mission"
              class='icon'
              src="../static/images/foundation/icon-mission.png"
            ></StaticImage>
            <p className='subtitle'>{intl.formatMessage({ id: 'Mission' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Technology Empowerment' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Ecosystem Developments' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Public Welfare Support' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Transparent Governance' })}</p>
          </motion.div>
        </div>
      </div>

      <div className='core-functions container'>
        <p className='title title-pc'>{intl.formatMessage({ id: 'Core Functions of the Foundation' })}</p>
        <p className='title title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
          {intl.formatMessage({ id: 'Core Functions of the Foundation' })}
          </span>
        </p>
        <div className='content-box' ref={ref3}>
          <motion.div className='content-item mb-6 md:mb-0'
            initial={{ x: 100, opacity: 0 }}
            animate={controls3}
            transition={{
              duration: 0.5,
              ease: "easeOut",
              delay: 0.3
            }}
          >
            <div className='left-line'>
            <p className='subtitle'>{intl.formatMessage({ id: 'Charity support' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Managing 15% to 30% of gas feecharity funds to drive project implementation' })}</p>
            </div>
          </motion.div>
          <motion.div className='content-item mb-6 md:mb-0'
            initial={{ x: 100, opacity: 0 }}
            animate={controls3}
            transition={{
              duration: 0.5,
              ease: "easeOut",
              delay: 0.6
            }}
          >
            <div className='left-line'>
            <p className='subtitle'>{intl.formatMessage({ id: 'Public Chain Development' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Supporting the research, development, and optimization of ABlockChain technology' })}</p>
            </div>
          </motion.div>
          <motion.div className='content-item'
            initial={{ x: 100, opacity: 0 }}
            animate={controls3}
            transition={{
              duration: 0.5,
              ease: "easeOut",
              delay: 0.9
            }}
          >
            <div className='left-line'>
            <p className='subtitle'>{intl.formatMessage({ id: 'Ecosystem Development' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Attracting developers, institutions, and join the ecosystem.' })}</p>
            </div>
          </motion.div>
          <motion.div className='content-item'
            initial={{ x: 100, opacity: 0 }}
            animate={controls3}
            transition={{
              duration: 0.5,
              ease: "easeOut",
              delay: 1.2
            }}
          >
            <div className='left-line'>
            <p className='subtitle'>{intl.formatMessage({ id: 'Community Governancence' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Encouraging community participation in decision-making to maintain network fairness.' })}</p>
            </div>
          </motion.div>
        </div>
      </div>

      <div className='directors container' ref={ref4}>
        <p className='title title-pc'>{intl.formatMessage({ id: 'Advisory Committee' })}</p>
        <p className='title title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
          {intl.formatMessage({ id: 'Advisory Committee' })}
          </span>
        </p>
        <motion.p className='director-desc mt-6 text-white'
            initial={{ x: 100, opacity: 0 }}
            animate={controls4}
            transition={{
              duration: 0.5,
              ease: "easeIn",
              delay: 0.3
            }}
        >
          {intl.formatMessage({ id: 'The ABlockChain Foundation has assembled a team of internationally renowned politicians; scholars; industry experts; and public figures as advisors to provide professional opinions and recommendations for the foundation’s development and strategic :planning:' })}
        </motion.p>
        {/* <p className='director-intr'>{intl.formatMessage({ id: 'Introduction to the Chairman of the Board' })}</p> */}
        <motion.div className='chairman'
            initial={{ x: 100, opacity: 0 }}
            animate={controls4}
            transition={{
              duration: 0.5,
              ease: "easeIn",
              delay: 0.6
            }}
        >
          <div className='director-item'>
          <Link to={`/${chairman.slug}`}>
            <StaticImage
              alt='director'
              src='../static/images/directors/BertieAhern.png'
              className='director-avatar'
            ></StaticImage>
            <div className='director-desc'>
              {/* <p className='name'>{ chairman.frontmatter.enName }</p> */}
              <p className='name'>{ chairman.frontmatter.title }</p>
              <p className='position'>{intl.formatMessage({ id: 'Chairman of the Board' })}</p>
              <p className='desc'>{ chairman.frontmatter.description }</p>
            </div>
          </Link>
          </div>
        </motion.div>
        {/* <p className='director-intr'>{intl.formatMessage({ id: 'Introduction to the Board Members' })}</p> */}
        <div className='members'>
          {members.length > 0 ? (
            members.map((edge: any, index: number) => {
              const { frontmatter, slug } = edge.node
              const avatarPath = frontmatter.enName ? frontmatter.enName.replaceAll(' ', '') : frontmatter.title.replaceAll(' ', '')
              const imagePath = require(`../static/images/directors/${avatarPath}.png`).default;
              return (
                <div className='director-item' key={index}>
                <Link to={`/${slug}`}>
                    <img src={imagePath} alt="director" className="director-avatar" />
                  <div className='director-desc'>
                    <h3>{frontmatter.title}</h3>
                    <p className='position'>{intl.formatMessage({ id: 'Board Member' })}</p>
                    <p className='desc'>{ frontmatter.description }</p>
                  </div>
                  </Link>
                </div>
              )
            })
          ) : null}
        </div>
        <span className='sort-by'>*{intl.formatMessage({ id: 'Sorted A–Z by name.' })}</span>
      </div>

      <div className='advisory-board container'>
        <p className='title title-pc'>{intl.formatMessage({ id: 'AB Foundation Senior Advisory Members Introduction' })}</p>
        <p className='title title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
            {intl.formatMessage({ id: 'AB Foundation Senior Advisory Members Introduction' })}
          </span>
        </p>
        <p className='advisory-desc'>{intl.formatMessage({ id: 'The Advisory Board will provide professional support in the following areas: the development and ecosystem planning of ABlockChain technology; the evaluation of charitable fund utilization; social responsibility and global influence; and the expansion of international cooperation resources.' })}</p>
        
        {/* {pagesWeb.length > 0 ? (
          <Carousel className={`h-full carousel-web ${isWebExpanded ? 'hide-all' : ''}`} autoplay>
            {pagesWeb.map((committees: any, index: number) => (
              <div className='members' key={index}>
                {committees.map((edge: any, subIndex: number) => {
                  if (edge && edge.node) {
                    const { frontmatter, slug } = edge.node;
                    const avatarPath = frontmatter.enName 
                      ? frontmatter.enName.replaceAll(' ', '') 
                      : frontmatter.title.replaceAll(' ', '');
                    const imagePath = require(`../static/images/committees/${avatarPath}.png`).default;
                    return (
                      <div className='director-item box-shadow' key={subIndex}>
                        <Link to={`/${slug}`}>
                          <img src={imagePath} alt="director" className="director-avatar aspect-square" />
                          <div className='director-desc'>
                            <h3>{frontmatter.title}</h3>
                            <p className='position'>{frontmatter.ABRank}</p>
                            <p>{frontmatter.description}</p>
                          </div>
                        </Link>
                      </div>
                    );
                  } else {
                    return (
                      <div className='director-item no-bg' key={subIndex}></div>
                    );
                  }
                })}
              </div>
            ))}
          </Carousel>
        ) : null}
         */}

        {/* <div className={`members ${isWebExpanded || isH5Expanded ? '' : 'hide-all'}`}> */}
        <div className={'members'}>
          {committees.length > 0 ? (
            committees.map((edge: any, index: number) => {
            if (edge && edge.node) {
              const { frontmatter, slug } = edge.node
              let avatarPath = ''
              if (frontmatter.iconName) {
                avatarPath = frontmatter.iconName
              } else {
                avatarPath = frontmatter.enName ? frontmatter.enName.replaceAll(' ', '') : frontmatter.title.replaceAll(' ', '')
              }
              const imagePath = require(`../static/images/committees/${avatarPath}.png`).default;
              return (
                <div className='director-item' key={index}>
                <Link to={`/${slug}`}>
                  <div className='img-box'>
                    <img src={imagePath} alt="director" className="director-avatar bg-avatar" />
                  </div>
                  <div className='director-desc'>
                    <h3>{frontmatter.title}</h3>
                    <p className='position'>{frontmatter.ABRank}</p>
                    <p className='desc'>{frontmatter.description}</p>
                  </div>
                  </Link>
                </div>
              )
            } else {
              return (
                <div className='director-item no-bg' key={index}></div>
              )
            }
            })
          ) : null}
          <div className='director-item'>
            <div className='img-box'>
              <img src={mask1} alt="director" className="director-avatar" />
            </div>
            <div className='director-desc'>
              <p className='mask-content'>{intl.formatMessage({ id: 'Gradually adding more advisory board members...' })}</p>
            </div>
          </div>
          <div className='director-item'></div>
          <div className='director-item'></div>
          <div className='director-item'></div>
          {/* <div className='director-item'>
            <div className='img-box'>
              <img src={mask2} alt="director" className="director-avatar" />
            </div>
            <div className='director-desc'>
              <p className='mask-content'>{intl.formatMessage({ id: 'Gradually adding more advisory board members...' })}</p>
            </div>
          </div>
          <div className='director-item'>
            <div className='img-box'>
              <img src={mask3} alt="director" className="director-avatar" />
            </div>
            <div className='director-desc'>
              <p className='mask-content'>{intl.formatMessage({ id: 'Gradually adding more advisory board members...' })}</p>
            </div>
          </div> */}
          
          <span className='sort-by'>*{intl.formatMessage({ id: 'Sorted A–Z by name.' })}</span>
        </div>
      </div>

      <div className='allocation-principles container'>
        <p className='ttitle'>{intl.formatMessage({ id: 'Fund Allocation Principles' })}</p>
        <div className='content-box'>
          <div className='content-left'>
            <StaticImage
              className="principles-img w-full h-full"
              alt="allocationPrinciples"
              src="../static/images/foundation/allocation-principles.png"
            ></StaticImage>
          </div>
          <div className='content-right' ref={ref8}>
            <motion.div className='principles-1'
              initial={{ x: 100, opacity: 0 }}
              animate={controls8}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.1
              }}
            >
              <p className='title'>{intl.formatMessage({ id: 'Technical Development and Maintenance' })}</p>
              <p className='desc'>{intl.formatMessage({ id: 'Supporting the development and upgrading of ABlockChain technology.' })}</p>
            </motion.div>
            <motion.div className='principles-2'
              initial={{ x: 100, opacity: 0 }}
              animate={controls8}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.4
              }}>
              <p className='title'>{intl.formatMessage({ id: 'Ecosystem Incentives' })}</p>
              <p className='desc'>{intl.formatMessage({ id: 'Funding innovative applications.' })}</p>
              <p className='desc'>{intl.formatMessage({ id: '15%–30% of investment returns are allocated to charity.' })}</p>
            </motion.div>
            <motion.div className='principles-3'
              initial={{ x: 100, opacity: 0 }}
              animate={controls8}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.7
              }}>
              <p className='title'>{intl.formatMessage({ id: 'Charitable Donations' })}</p>
              <p className='desc'>{intl.formatMessage({ id: '15% to 30% of gas fees are allocated to charity.' })}</p>
            </motion.div>
            <motion.div className='principles-4'
              initial={{ x: 100, opacity: 0 }}
              animate={controls8}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 1
              }}>
              <p className='title'>{intl.formatMessage({ id: 'Community Promotion' })}</p>
              <p className='desc'>{intl.formatMessage({ id: 'Developer competitions, hackathons, technical seminars, and community event funds.' })}</p>
            </motion.div>
          </div>
        </div>
      </div>
      <div className='allocation-principles-h5 container'>
        <p className='ttitle title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
            {intl.formatMessage({ id: 'Fund Allocation Principles' })}
          </span>
        </p>
        <div className='content-box'>
          <div className='content-left'>
            <StaticImage
              className="w-full"
              alt="allocationPrinciples"
              src="../static/images/foundation/allocation-principles-h5.png"
            ></StaticImage>
          </div>
          <div className='content-right' ref={ref5}>
            <motion.div className='principles-1'
              initial={{ x: 100, opacity: 0 }}
              animate={controls5}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.1
              }}
            >
              <p className='title'>{intl.formatMessage({ id: 'Technical Development and Maintenance' })}</p>
              <p className='desc'>{intl.formatMessage({ id: 'Supporting the development and upgrading of ABlockChain technology.' })}</p>
            </motion.div>
            <motion.div className='principles-2'
              initial={{ x: 100, opacity: 0 }}
              animate={controls5}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.4
              }}
            >
              <p className='title'>{intl.formatMessage({ id: 'Ecosystem Incentives' })}</p>
              <p className='desc'>{intl.formatMessage({ id: 'Funding innovative applications.' })}</p>
              <p className='desc'>{intl.formatMessage({ id: '15%–30% of investment returns are allocated to charity.' })}</p>
            </motion.div>
            <motion.div className='principles-3'
              initial={{ x: 100, opacity: 0 }}
              animate={controls5}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.7
              }}
            >
              <p className='title'>{intl.formatMessage({ id: 'Charitable Donations' })}</p>
              <p className='desc'>{intl.formatMessage({ id: '15% to 30% of gas fees are allocated to charity.' })}</p>
            </motion.div>
            <motion.div className='principles-4'
              initial={{ x: 100, opacity: 0 }}
              animate={controls5}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 1
              }}
            >
              <p className='title'>{intl.formatMessage({ id: 'Community Promotion' })}</p>
              <p className='desc'>{intl.formatMessage({ id: 'Developer competitions, hackathons, technical seminars, and community event funds.' })}</p>
            </motion.div>
          </div>
        </div>
      </div>

      <div className='operation-model container'>
        <p className='title title-pc'>{intl.formatMessage({ id: 'Operation Model of the Charitable Foundation' })}</p>
        <p className='title title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
            {intl.formatMessage({ id: 'Operation Model of the Charitable Foundation' })}
          </span>
        </p>
        <div className='content-box' ref={ref6}>
          <motion.div className='content-left'
            initial={{ x: -120, opacity: 0 }}
            animate={controls6}
            transition={{
              duration: 0.5,
              ease: "easeIn",
              delay: 0.3
            }}
          >
            <StaticImage
              alt="operation model"
              class='icon'
              src="../static/images/foundation/operation.png"
            ></StaticImage>
          </motion.div>
          <div className='content-right' ref={ref7}>
            <motion.div className='oper-item'
              initial={{ x: 120, opacity: 0 }}
              animate={controls7}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.3
              }}
            >
              <span></span>
              <p className='subtitle'>{intl.formatMessage({ id: 'Funding Sources' })}</p>
            </motion.div>
            <motion.p className='desc'
              initial={{ x: 120, opacity: 0 }}
              animate={controls7}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.3
              }}
            >
              {intl.formatMessage({ id: '15% to 30% of gas fees and other donations.' })}
              <br/>
              {intl.formatMessage({ id: '15%–30% of the investment returns from all AB Ecosystem Fund projects will also be injected into the AB Charity Foundation pool.' })}
            </motion.p>
            <motion.div className='oper-item'
              initial={{ x: 120, opacity: 0 }}
              animate={controls7}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.6
              }}
            >
              <span></span>
              <p className='subtitle'>{intl.formatMessage({ id: 'Fund Usage' })}</p>
            </motion.div>
            <motion.p className='desc'
              initial={{ x: 120, opacity: 0 }}
              animate={controls7}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.6
              }}
            >
              {intl.formatMessage({ id: 'Regularly reviewed and donated to reputablecharitable orqanizations.' })}
            </motion.p>
            <motion.div className='oper-item'
              initial={{ x: 120, opacity: 0 }}
              animate={controls7}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.9
              }}
            >
              <span></span>
              <p className='subtitle'>{intl.formatMessage({ id: 'Transparent Management' })}</p>
            </motion.div>
            <motion.p className='desc'
              initial={{ x: 120, opacity: 0 }}
              animate={controls7}
              transition={{
                duration: 0.5,
                ease: "easeIn",
                delay: 0.9
              }}
            >
              {intl.formatMessage({ id: 'Publicly recorded on the blockchain and open to supervision.' })}
            </motion.p>
          </div>
        </div>
      </div>


      <div className='structure container'>
        <p className='title title-pc'>{intl.formatMessage({ id: 'Foundation Governance Structure' })}</p>
        <p className='title title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
            {intl.formatMessage({ id: 'Foundation Governance Structure' })}
          </span>
        </p>
        <div className='content-box'>
          <div className='content-item'>
            <StaticImage
              alt="govern"
              class='icon'
              src="../static/images/foundation/govern-1.png"
            ></StaticImage>
            <p className='subtitle'>{intl.formatMessage({ id: 'Board of Directors' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'The highest decision making body, responsible for setting strategic direction.' })}</p>
          </div>
          <div className='content-item'>
            <StaticImage
              alt="govern"
              class='icon'
              src="../static/images/foundation/govern-2.png"
            ></StaticImage>
            <p className='subtitle'>{intl.formatMessage({ id: 'Charity Committee' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'the utilization of charitable funds.' })}</p>
          </div>
          <div className='content-item'>
            <StaticImage
              alt="govern"
              class='icon'
              src="../static/images/foundation/govern-3.png"
            ></StaticImage>
            <p className='subtitle'>{intl.formatMessage({ id: 'Executive Team' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Responsible for daily operations and project implementation.' })}</p>
          </div>
          <div className='content-item'>
            <StaticImage
              alt="govern"
              class='icon'
              src="../static/images/foundation/govern-4.png"
            ></StaticImage>
            <p className='subtitle'>{intl.formatMessage({ id: 'Supervisory Committee' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'Reviews the compliance of the foundation’s operations.' })}</p>
          </div>
        </div>
      </div>
      
      <div className='aboutus'>
        <p className='title'>{intl.formatMessage({ id: 'About Us' })}</p>
        <div className='content-box container'>
          <p>
            {intl.formatMessage({ id: 'About Us Detail Left' })}
            <a
              href="#!" 
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >{intl.formatMessage({ id: 'View the registration certificate' })}</a>
            {intl.formatMessage({ id: 'About Us Detail Right' })}
          </p>
        </div>
      </div>

      <div className='legal-advisory container'>
        <p className='title title-pc'>{intl.formatMessage({ id: 'Legal Advisory Team' })}</p>
        <p className='title title-h5'>
          <span className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-blue2B">
            {intl.formatMessage({ id: 'Legal Advisory Team' })}
          </span>
        </p>
        <p className='desc'>{intl.formatMessage({ id: 'Our legal advisory team is comprised of esteemed law firms, bringing together specialized attorneys from various fields to provide comprehensive legal support to the AB Charity Foundation. This ensures operational compliance and fosters the healthy development of charitable endeavors.' })}</p>
        <a className='law-img-box' href='https://www.pmt.com.hk/' target='_blank'>
        <StaticImage
          alt="Legal Advisory Team"
          class='legal-image'
          src="../static/images/foundation/legal.png"
        ></StaticImage></a>
        <p className='law-society'>
          <a href='https://www.hklawsoc.org.hk/en/Serve-the-Public/The-Law-List/Firm-Detail?FirmId=844' target='_blank'>
          {intl.formatMessage({ id: 'Law Society of Hong Kong' })}（Law Society of Hong Kong）</a>
        </p>
        <p className='addition'>{intl.formatMessage({ id: 'We will soon be partnering with additional law firms.' })}</p>
      </div>

      {isModalOpen && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.7)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setIsModalOpen(false)}
        >
          <div style={{ position: 'relative' }}>
            <span
              style={{
                position: 'absolute',
                top: '-52px',
                left: '0px',
                fontSize: '28px',
                color: 'white'
              }}
            >注册证书</span>
            <span
              style={{
                position: 'absolute',
                top: '-70px',
                right: '0px',
                border: 'none',
                fontSize: '48px',
                cursor: 'pointer',
                color: 'white'
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(false);
              }}
            >
              ×
            </span>
            
            {/* 图片 */}
            <img
              src={Certificate} // 替换为你的图片路径
              alt="Certificate of Incorporation"
              style={{
                maxWidth: '90vw',
                maxHeight: '85vh',
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}